<template lang="pug">
.subscription(v-if="subscription != null")
  .subscription-status(v-html="subscriptionStatusHtml")

  .subscription-plan
    | Plano:&#32;
    span.magenta-text {{ planName }}

  .subscription-next-renewal-at(v-if="!simplified && nextRenewalAt")
    | Próxima renovação:&#32;
    span.magenta-text {{ nextRenewalAt }}

  .subscription-next-renewal-at(v-if="renewalHasBeenCanceled")
    | Renovação:&#32;
    span.magenta-text cancelada

  .subscription-end-at(v-if="renewalHasBeenCanceled && endAt")
    | Ativa até:&#32;
    span.magenta-text {{ endAt }}

  .subscription-actions
    AlternativePaymentPixButton(
      v-if="pendingInvoice",
      :invoice="pendingInvoice",
      :subscription="subscription"
    )

    Button(
      v-if="renewalHasBeenCanceled",
      type="text",
      label="Reativar assinatura",
      size="small",
      @click="openReactivateSubscriptionDialog"
    )

    Button(
      v-if="!simplified && bankSlipUrl",
      type="text",
      label="Ver boleto bancário",
      :to="bankSlipUrl",
      target="_blank",
      rel="noopener noreferrer",
      :disabled="!bankSlipUrl",
      size="small"
    )

    Dialog(title="Reativar assinatura", v-model:open="showReactivateSubscriptionDialog", width="640px")
      template(v-slot)
        p Tem certeza de que deseja reativar sua assinatura?

      template(v-slot:footer="{ close }")
        .confirm-dialog-actions(style="text-align: right")
          Button(label="Cancelar", plain, size="small", @click="close")
          Button(label="Reativar assinatura", size="small", @click="reactiveSubscription", :loading="reactivatingSubscription")

.subscription(v-else)
  .no-subscription
    | Você não possui&#32;
    span.magenta-text nenhuma assinatura ativa!

    .no-subscription-action
      Button(:to="{ name: 'subscribe' }", label="Assine Já!", rounded)

</template>
<script setup lang="ts">
import type { PropType } from 'vue'
import type { PrimarySubscription } from '~/services/subscription/subscriptionService'
import { InvoiceStatus, type Invoice } from "~/services/invoice/invoiceService"

import { planName as planNameMapping } from '~/services/subscription/subscriptionService'

import pancakeService from '~/services/pancake/pancakeService'
import { useActiveSubscription } from '~/store/subscription/subscription'

const props = defineProps({
  subscription: {
    type: Object as PropType<PrimarySubscription>,
  },
  invoices: {
    type: Object as PropType<Invoice[]>
  },
  simplified: {
    type: Boolean,
    default: true,
  },
})

const dayjs = useDayjs()

const activeSubscription = useActiveSubscription()

const subscriptionStatusHtml = computed(() => {
  if (!props.subscription) {
    return ''
  }

  switch(props.subscription.status) {
    case 'WAITING':
      if (props.subscription.migratedFrom) {
        return 'Sua assinatura está <span class="magenta-text">ativa</span>'
      } else {
        return 'Sua assinatura está aguardando pela <span class="magenta-text">confirmação de pagamento</span>'
      }
    case 'ACTIVE':
      return 'Sua assinatura está <span class="magenta-text">ativa</span>'
    case 'PENDING':
    case 'UNPAID':
      return 'Sua assinatura possui <span class="magenta-text">pagamentos pendentes</span>'
    default:
      return 'Sua assinatura foi <span class="magenta-text">cancelada</span>'
  }
})

const isPending = computed(() => {
  return props.subscription?.status == 'PENDING' || props.subscription?.status == 'UNPAID'
})

const planName = computed(() => {
  if (!props.subscription) {
    return ''
  }

  return planNameMapping[props.subscription.planId] ?? ""
})

const nextRenewalAt = computed(() => {
  if (!props.subscription?.nextRenewalAt) {
    return null
  }

  return formattedDate(props.subscription.nextRenewalAt)
})

const nextBillingAt = computed(() => {
  if (!props.subscription?.nextBillingAt) {
    return null
  }

  return formattedDate(props.subscription.nextBillingAt)
})

const renewalHasBeenCanceled = computed(() => {
  if (!props.subscription) {
    return false
  }

  return props.subscription.endAt != null
})

const endAt = computed(() => {
  if (!props.subscription?.endAt) {
    return null
  }

  return formattedDate(props.subscription.endAt)
})

const bankSlipUrl = computed(() => {
  return props.subscription?.pendingBankSlipUrl
})

const pendingInvoice = computed(() => {
  const invoices = props.invoices
  if (!isPending.value || !invoices) {
    return
  }

  return invoices
    .filter(i => i.status == InvoiceStatus.PENDING || i.status == InvoiceStatus.UNPAID)
    .sort((a, b) => a.chargeAt < b.chargeAt ? 1 : -1)[0] ?? null
})

const showCancelSubscription = computed(() => {
  return !props.simplified && !renewalHasBeenCanceled.value
})

// Reactivate subscription dialog
const showReactivateSubscriptionDialog = ref(false)
const reactivatingSubscription = ref(false)

const openReactivateSubscriptionDialog = () => {
  showReactivateSubscriptionDialog.value = true
}

const reactiveSubscription = async () => {
  reactivatingSubscription.value = true

  try {
    await pancakeService.reactivateSubscription(props.subscription!.id)

    showReactivateSubscriptionDialog.value = false

    activeSubscription.refresh()

    gtmPush({
      event: 'reactivated-subscription',
      subscriptionId: props.subscription!.id,
      planId: props.subscription!.planId,
    })
  } catch (error) {
    console.error(error)
  } finally {
    reactivatingSubscription.value = false
  }
}
</script>
<style lang="sass" scoped>
.subscription
  position: relative
  text-align: left
  font-size: 18px
  margin: 0 0 32px

  .subscription-actions
    margin-top: 16px

  .no-subscription
    .no-subscription-action
      text-align: center
      padding: 32px 0

      a
        width: 240px
        height: 48px
        border-radius: 24px
        font-size: 24px
        font-weight: bold
        box-shadow: 0 4px 24px 0 rgba(238, 61, 138, 0.4)
</style>
